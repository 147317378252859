@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.container {
  background-image: url("/assets/images/background.png");
  padding: 1rem 3rem;
  margin-top: 3rem;

  &_contents {
    padding: 4rem;
    text-align: left;
    @include sm {
      padding: 0.3rem;
    }

    &_header {
      text-transform: uppercase;
      margin-bottom: $base;
      color: $lightBlue;
    }
    &_semiheader {
      margin-bottom: $base;
      font-size: 2rem;
      font-weight: 600;
      @include sm {
        font-size: 1.3rem;
      }
    }

    &_checks {
      display: flex;
      gap: 2rem;
      align-items: center;
    }

    &_btn {
      background-color: inherit;
      color: $lightBlue;
      border: 1px solid $lightBlue;
      border-radius: 17px;
      padding: $base $xxlg;

      @include sm {
        display: none;
      }
    }

    &_btnMobile {
      display: none;

      @include sm {
        display: flex;
        background-color: inherit;
        color: $lightBlue;
        border: 1px solid $lightBlue;
        border-radius: 17px;
        padding: $tiny $base;
        align-self: center;
      }
    }
  }

  &_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @include sm {
      grid-template-columns: repeat(1, 1fr);
    }

    &_card {
      min-height: 40vh;
      min-width: 15vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: $base $xxlg;
      // background-color: rgb(255, 251, 251);
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      border-radius: 10px;
    }
  }
}
