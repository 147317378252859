@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.container {
  // width: 100%;
  // height: 100%;
  // position: relative;

  &_video {
    min-width: 100%;
    max-width: 100%;
    max-height: 90vh;
    object-fit: cover;
    // position: fixed;
    // opacity: 70%;
    // left: 0;
    // top: 0;

    @include sm {
      object-fit: cover;
      min-height: 60vh;
      max-height: 60vh;
      // display: none;
    }
  }

  &_image {
    background-image: url("/assets/images/hero.jpg");
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 100%;
    display: none;

    // @include sm {
    //   display: block;
    //   height: 60vh;
    // }
  }

  &_content {
    // background-color: rgb(219, 242, 252);
    background-color: inherit;
    color: white;
    opacity: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    position: absolute;
    top: 18%;
    left: 3%;
    max-width: 35vw;
    padding: 2rem;
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include sm {
      top: 15%;
      left: 5%;
      max-width: 90vw;
      padding: 3rem 1rem;
      align-items: center;
      background-color: inherit;
      // box-shadow: none;
      opacity: 100%;
    }

    &_header {
      color: #fff;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 2rem;
      @include sm {
        color: #fff;
        font-size: 2rem;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        text-align: center;
      }
    }
    &_text {
      font-size: 1.2rem;
      font-weight: 500;
      max-width: fit-content;
      text-align: justify;
      @include sm {
        display: none;
      }
      &_span {
        font-size: 1.3rem;
        font-weight: 800;
      }
    }
    &_btn {
      width: 15vw;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: capitalize;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

      @include sm {
        width: 100%;
      }
    }
  }
}
