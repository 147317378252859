@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.main {
  // margin-top: $outlet;
  &_container {
    position: relative;
    margin-bottom: $xlg;
    &_bg {
      width: 100%;
      height: 50vh;
      opacity: 90%;
      @include sm {
        height: 35vh;
      }
    }

    &_text {
      position: absolute;
      top: 50%;
      left: 35%;
      right: 35%;
      color: aliceblue;
      font-size: 3rem;
      font-weight: 600;

      @include sm {
        position: absolute;
        top: 55%;
        left: 10%;
        right: 10%;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  &_row {
    max-width: 100%;
    @include sm {
      max-width: 100vw;
    }
  }

  &_card {
    background-color: rgb(0, 185, 209);
    padding: $xxxlg;
    margin-bottom: $xxlg;
    color: rgb(30, 29, 29);
    font-weight: 600;
    @include sm {
      padding: $xlg;
      min-width: 100vw;
    }
    &_h5 {
      @include sm {
        font-size: 1.1rem;
      }
    }
    &_h1 {
      @include sm {
        font-size: 1.4rem;
      }
    }

    &_span {
      color: aliceblue;
    }
  }

  &_left {
    margin: 2rem 8rem;
    text-align: left;
    @include sm {
      margin: 2rem;
      max-width: 100vw;
    }
  }

  &_right {
    // border: 1px solid $borderColor;
    background-image: url("/assets/images/background.png");
    background-color: rgb(226, 242, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding: 8rem 5rem;
    margin: 6rem;
    text-align: left;
    color: $fontColor;
    @include sm {
      display: none;
    }

    &_head {
      color: $fontColor;
      font-weight: 700;
      font-size: 2rem;
      margin: $base 0;
    }

    &_text {
      margin: $base 0;
      font-size: $base;
    }

    &_details {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: $xxlg;
      margin-top: $xlg;

      &_check {
        display: flex;
        align-items: center;
        gap: $xxlg;
      }
    }
  }
}
