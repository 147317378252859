@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.main {
  // margin-top: $outlet;
  &_container {
    position: relative;
    margin-bottom: $xlg;
    &_bg {
      width: 100%;
      height: 50vh;
      opacity: 90%;
      @include sm {
        height: 35vh;
      }
    }

    &_text {
      position: absolute;
      top: 50%;
      left: 35%;
      right: 35%;
      color: aliceblue;
      font-size: 3rem;
      font-weight: 600;

      @include sm {
        position: absolute;
        top: 55%;
        left: 8%;
        right: 8%;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  &_row {
    max-width: 100vw;
  }

  &_left {
    margin: 2rem 8rem;
    text-align: left;
    @include sm {
      margin: 1rem 2rem;
    }
  }

  &_right {
    border: 1px solid $borderColor;
    min-height: 70vh;
    padding: 8rem 5rem;
    margin-right: 8rem;
    text-align: left;
    color: $fontColor;

    @include sm {
      margin: 1rem 1rem;
      padding: 2rem;
      min-height: 50vh;
    }

    &_head {
      color: $fontColor;
      font-weight: 700;
      font-size: 2rem;
      margin: $base 0;
      @include sm {
        font-size: 1.5rem;
      }
    }

    &_text {
      margin: $base 0;
      font-size: $base;
    }

    &_details {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: $xxlg;
      margin-top: $xlg;

      &_check {
        display: flex;
        align-items: center;
        gap: $xxlg;
      }
    }
  }
}
