@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

$font: "Lato", sans-serif;
$navBgColor: #edf2f6;
$appBgColor: #f4f4f4;
$darkBlue: #213f7d;
$lightBlue: #2596be;
$blue: #0779f7;
$summaryCard: #83b6f1;
$darkRed: #950303;
$tableBg: #fff;
$lightRed: #b70303;
$fontColor: #545f7d;
$borderColor: #545f7d26;
$boxShadowColor: #0000000a;
$white: #ffffff;
$error: #e4033b;
$sunYellow: #e9b200;
$yellow: #e8b533;
$green: #39cd62;
$tiny: 0.625rem;
$xs: 0.75rem;
$md: 0.875rem;
$base: 1rem;
$lg: 1.25rem;
$xlg: 1.5rem;
$xxlg: 2.5rem;
$xxxlg: 3.5rem;
$outlet: 10rem;
