@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.main_container {
  position: relative;
  margin-bottom: $xlg;
  &_bg {
    width: 100%;
    height: 50vh;
    opacity: 90%;
    @include sm {
      height: 35vh;
      max-width: 100vw;
    }
  }

  &_text {
    position: absolute;
    top: 50%;
    left: 35%;
    right: 35%;
    color: aliceblue;
    font-size: 3rem;
    font-weight: 600;

    @include sm {
      position: absolute;
      top: 55%;
      left: 10%;
      right: 10%;
      font-size: 2rem;
      font-weight: 600;
    }
  }
}
.container {
  margin-top: $base;
  background-image: url("/assets/images/background.png");
  // background-size: contain;
  padding: 1rem 3rem;
  @include sm {
    padding: 1rem;
  }

  &_h2 {
    @include sm {
      font-size: 1.2rem;
    }
  }
  &_p {
    @include sm {
      font-size: 0.9rem;
    }
  }

  &_row {
    max-width: 100%;
    @include sm {
      max-width: 100vw;
    }
  }

  &_head {
    text-align: left;
    margin-bottom: $xlg;
    color: $fontColor;
    @include sm {
      font-size: 1.3rem;
    }
  }

  &_sub {
    text-align: left;
    margin-bottom: $xlg;
    color: $fontColor;
    @include sm {
      font-size: 1rem;
    }
  }

  &_contents {
    padding: 4rem;
    text-align: left;
    @include sm {
      padding: 1rem;
    }

    &_header {
      text-transform: uppercase;
      margin-bottom: $base;
      color: $lightBlue;
    }
    &_semiheader {
      margin-bottom: $base;
      font-size: 2rem;
      font-weight: 600;
    }

    &_checks {
      display: flex;
      gap: 2rem;
      align-items: center;
    }

    &_btn {
      background-color: inherit;
      color: $lightBlue;
      border: 1px solid $lightBlue;
      border-radius: 17px;
      padding: $base $xxlg;
      @include sm {
        padding: 1rem;
      }
    }
  }

  &_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    @include sm {
      grid-template-columns: repeat((1, 1fr));
      gap: 1.6rem;
      max-width: 100vw;
    }

    &_card {
      min-height: 35vh;
      min-width: 15vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding-bottom: $xlg;
      // background-color: rgb(255, 251, 251);
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      border-radius: 10px;
      color: $fontColor;

      @include sm {
        min-height: 20vh;
        max-width: 100vw;
      }

      &_label {
        background: $lightBlue;
        border-radius: 10px 10px 0 0;
        color: $white;
        padding: $base;
        width: 100%;
        min-height: 10vh;
        font-size: 0.9rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: $tiny;
        @include sm {
          max-width: 100vw;
        }
      }

      &_text {
        margin: 0 $xxlg;
      }

      &_btn {
        background-color: $white;
        color: $lightBlue;
        border: 1px solid $borderColor;
        min-width: 10vw;

        &:hover {
          background-color: $lightBlue;
          color: aliceblue;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}
