@import "../scss/sass-variables";
@import "../scss/media-query-mixins";

.text-input {
  display: block;
  width: 100%;
  min-height: 2.7rem;
  color: $fontColor;
  font-size: $md;
  border: 2px solid $borderColor;
  border-radius: 5px;
  padding: $base;
  outline: none;
  box-sizing: border-box;
}

.text-label {
  color: $fontColor;
  font-weight: 500;
  font-size: $base;

  margin: $tiny 0;
}

.error-active {
  border: 2px solid $error;
}

.helperError {
  font-size: $xs;
  color: $error;
  text-align: left;
  margin: 0;
  padding: 0;
}

.button {
  background: $lightBlue;
  align-self: center;
  color: $white;
  padding: $tiny;
  outline: none;
  border-radius: $tiny;
  border: none;
  font-size: $base;
  min-width: 6vw;
  margin-top: $tiny;
}
