@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.container {
  margin: $base 8rem;
  margin-top: 5rem;
  display: flex;

  @include sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $tiny;
  }

  &_left {
    width: 30%;

    @include sm {
      width: 100vw;
    }
    &_hello {
      background-color: rgb(21, 125, 237);
      min-height: 35vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: aliceblue;
      align-items: center;
      border-radius: 10px;
      gap: 2rem;
      @include sm {
        margin: $base;
        min-height: 25vh;
      }

      &_btn {
        background-color: aliceblue;
        color: rgb(21, 125, 237);
        font-size: $lg;
        border-radius: 20px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  &_right {
    @include sm {
      max-width: 100vw;
    }
    &_news {
      background-image: url("/assets/images/background.png");
      background-color: rgb(226, 242, 243);
      min-height: 35vh;
      max-width: 100%;
      display: flex;
      margin-left: 2rem;
      padding: 2rem 10rem;
      background-size: contain;
      gap: 3rem;
      @include sm {
        max-width: 100vw;
        margin: $base 0;
        padding: $base;
      }

      &_image {
        @include sm {
          display: none;
        }
      }

      &_mail {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: left;

        &_head {
          max-width: 15vw;
          @include sm {
            max-width: 90vw;
          }
        }
        &_input {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;
          border-radius: 6px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          @include sm {
            max-width: 80vw;
          }

          &_field {
            height: 70%;
            outline: none;
            border: 0;
            background-color: inherit;
            @include sm {
              max-width: 45vw;
            }
          }
        }
      }
    }
  }
}
