@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.main {
  &_container {
    position: relative;
    margin-bottom: $xlg;
    &_bg {
      width: 100%;
      height: 50vh;
      opacity: 90%;
      @include sm {
        height: 35vh;
      }
    }

    &_text {
      position: absolute;
      top: 50%;
      left: 35%;
      right: 35%;
      color: aliceblue;
      font-size: 3rem;
      font-weight: 600;

      @include sm {
        position: absolute;
        top: 55%;
        left: 10%;
        right: 10%;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  &_values {
    margin: 6rem 0;
    background-color: rgb(203, 242, 238);
    padding: 5rem 8rem;
    color: $fontColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $base;
    @include sm {
      margin: 3rem 0;
      padding: 2rem;
    }

    &_header {
      color: $lightBlue;
      font-weight: 600;
    }

    &_text {
      max-width: 50vw;
      @include sm {
        max-width: 100vw;
      }
    }

    &_lists {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $xxlg;
      margin-top: $xlg;
      @include sm {
        grid-template-columns: repeat(1, 1fr);
        gap: $xlg;
      }

      &_list {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: $base;
        border-radius: 5px;
        border: 1px solid $borderColor;
        &_label {
          color: $lightBlue;
          font-weight: 600;
        }
        &_text {
          text-align: left;
          line-height: $xxlg;
        }
      }
    }
  }

  &_position {
    max-width: 100%;
    @include sm {
      max-width: 100vw;
    }

    &_right {
      @include sm {
        display: none;
      }
    }

    &_left {
      text-align: left;
      margin: $xlg 6rem;
      padding: $xxlg;
      background: $appBgColor;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      @include sm {
        margin: 2rem;
        max-width: 90vw;
        padding: $base;
      }

      &_head {
        color: $lightBlue;
        font-weight: 600;
        font-size: $xlg;
        text-transform: uppercase;
        text-decoration: underline;
        margin-bottom: $xlg;
      }

      &_lists {
        display: flex;
        flex-direction: column;
        gap: $xlg;
        color: $fontColor;

        &_items {
          display: flex;
          gap: $xlg;

          &_list {
            &_label {
              font-weight: 600;
            }
            &_text {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
