@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  opacity: 90%;
  padding: 10px 20px 3px 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  position: fixed;
  width: 100%;
  z-index: 20;
}

.logo {
  color: #fff;
  background-image: url("/assets/images/background.png");
  background-size: contain;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 5px 0;
  font-weight: 700;
  cursor: pointer;
  background: -webkit-linear-gradient(45deg, #09009f, #32d390 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbar li p {
  background-color: #e5eff1;
  min-width: 5vw;
  padding: 5px;
  border-radius: 20px 5px 20px 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: $lightBlue;
  margin-top: 1.5rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
#navbar li p:hover,
#navbar li p.active {
  color: $lightBlue;
}

#navbar li p:hover::after,
#navbar li p.active::after {
  content: "";
  width: 30%;
  height: 2px;
  background: $lightBlue;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

#mobile {
  display: none;
}

#mobile .icon {
  color: #fff;
  align-items: center;
}

@media screen and (max-width: 769px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 115px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: #1b2430;
    // background: -webkit-linear-gradient(45deg, #000d18, #000d18 80%);
    -webkit-background-clip: #000d18;
    -webkit-text-fill-color: #fff;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
    z-index: 50;
  }

  nav {
    background: #1b2430;
  }

  #navbar li p {
    background-color: transparent;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile .icon {
    font-size: 24px;
    cursor: pointer;
  }
}

.content_btn {
  // width: 10vw;
  // font-size: 1.2rem;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  // margin-bottom: 10px;
}
