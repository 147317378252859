@import "../../scss/_media-query-mixins.scss";

.app-layout {
  display: flex;
  flex-direction: column;
  flex: 1;

  &-container {
    flex: 1;
    display: flex;

    &-contents {
      flex: 1;
      // padding: 1.25rem;
      // max-height: 100vh;
      min-height: 100vh;
      // overflow-y: scroll;

      @include sm {
        // padding: 1.5rem;
      }

      @include lg {
        // padding: 2.5rem;
      }

      @include xl {
        // padding: 3.75rem;
      }
    }
  }
  &-footer {
    bottom: 0;
    left: 0;
  }
}
