@import "../../scss/_media-query-mixins.scss";
@import "../../scss/_sass-variables.scss";

.page-not-found {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: $xlg;
  justify-content: center;
  align-items: center;
  height: 100%;

  &-img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: contain;
  }

  &-text {
    color: $darkBlue;
    text-align: center;

    @include sm {
      font-size: $lg;
    }
  }

  &-home-btn {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background-color: $lightBlue;
    padding: $base $xlg;
    border-radius: 5px;
    color: $white;
    font-size: $base;
  }
}
