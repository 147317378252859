@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.footer {
  background: #1b2430;
  opacity: 90%;
  padding: 30px 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  width: 100%;
  z-index: 20;
  color: $white;
}
.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  @include sm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  &_links {
    display: flex;
    justify-content: space-between;
    min-width: 30%;

    @include sm {
      display: flex;
      gap: 2rem;
    }
  }

  &_brand {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &_logo {
      color: #fff;
      background-image: url("/assets/images/background.png");
      background-size: contain;
      font-size: 1.5rem;
      text-transform: uppercase;
      padding: 0;
      margin: 0 0 5px 0;
      font-weight: 700;
      cursor: pointer;
      background: -webkit-linear-gradient(45deg, #09009f, #32d390 80%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_info {
      color: $white;
      text-align: justify;
    }

    &_socials {
      color: $white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $base;
      font-size: 1.8rem;

      &_icon {
        cursor: pointer;
      }
    }
  }

  &_pages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $tiny;

    &_bullet {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $xlg;
      font-size: $base;
    }

    &_label {
      font-size: $lg;
      font-weight: 600;
      position: relative;
      padding-bottom: 5px;
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 20px;
        height: 2px;
        background: $lightBlue;
        bottom: 0;
        left: 0;
      }
    }
  }
}
