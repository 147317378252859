@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.main {
  &_container {
    position: relative;
    margin-bottom: $xlg;
    &_bg {
      width: 100%;
      height: 50vh;
      opacity: 90%;
      @include sm {
        height: 35vh;
      }
    }

    &_text {
      position: absolute;
      top: 50%;
      left: 35%;
      right: 35%;
      color: aliceblue;
      font-size: 3rem;
      font-weight: 600;

      @include sm {
        position: absolute;
        top: 55%;
        left: 10%;
        right: 10%;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  &_about {
    margin: 4rem 8rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding: 4rem 10rem;
    border-radius: 10px;
    color: $fontColor;
    @include sm {
      margin: 2rem 1rem;
      max-width: 100vw;
      padding: 2rem;
      text-align: justify;
    }
  }

  &_meet {
    color: $lightBlue;
    font-weight: 600;
    margin-top: $xlg;
  }

  &_team {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 4rem;
    gap: 2rem;
    background-image: url("/assets/images/background.png");
    background-size: contain;

    @include sm {
      grid-template-columns: repeat(1, 1fr);
      margin: 2rem 0.6rem;
    }

    &_card {
      background-color: rgb(226, 242, 243);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      border-radius: 8px;
      padding: 1rem;

      &_image {
        border: 2px solid $borderColor;
        border-radius: 50%;
        min-width: 200px;
        max-width: 200px;
        min-height: 200px;
        max-height: 200px;
        margin-bottom: 0.8rem;
      }
    }
  }
}
