@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.sponsor {
  display: flex;
  gap: 10rem;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;

  @include sm {
    max-width: 100vw;
  }
}

.cards {
  display: flex;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  background-color: $appBgColor;
  margin: 5rem 2rem;

  @include sm {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 2rem 2rem;
    margin: 5rem auto;
  }

  .card {
    min-height: 40vh;
    min-width: 15vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: $base $xxlg;
    background-color: rgb(255, 251, 251);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 10px;

    @include sm {
      // min-width: 40vw;
    }

    &_btn {
      background-color: $white;
      color: $lightBlue;
      border: 1px solid $borderColor;
      min-width: 10vw;
    }
  }
}

.features {
  display: flex;
  gap: 3rem;
  margin-top: $xlg;

  @include sm {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 100vw;
  }

  &_contents {
    width: 50%;
    padding: 4rem 8rem;
    text-align: left;

    @include sm {
      max-width: 90vw;
      padding: 2rem;
      text-align: left;
    }

    &_header {
      text-transform: uppercase;
      margin-bottom: $base;
      color: $lightBlue;
    }
    &_semiheader {
      margin-bottom: $base;
      font-size: 2rem;
      width: 25vw;
      font-weight: 600;
      @include sm {
        width: 80vw;
      }
    }
    &_text {
      @include sm {
        width: 80vw;
      }
    }

    &_checks {
      display: flex;
      gap: 2rem;
      align-items: center;
      @include sm {
        min-width: 80vw;
      }
    }
  }

  &_banner {
    @include sm {
      display: none;
    }
  }
}
